import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import i18n from '../i18n'

Vue.use(Vuex)

import {call_url, date_to_char, char_to_date, date_add} from '@/library/misFunciones.js';

export default new Vuex.Store({
  state: {
    version: '1.1.41', //incluir en getParametros.php despues de publicar la aplicacion VUE
    cargando: {
      texto: '',
      estado: false
    },
    mensaje: {
      texto: '',
      isError: false,
      isWarning: false,
      estado: false,
      color: ''
    },
    sesion: {
      tag: '',
      cod_usuario: 0,
      nombre_usuario: '',
      num_empresas: 0,
      empresa: null, //solo se usa para saber a que servidor atacar segun sea par o impar
      nombreEmpresa: '', //para mostrarlo en el dashBoard
      existe: false,
      mostrar_autenticacion: false,
      mostrar_logout: false,
      mostrar_empresa: false,
      // mostrar_usuario: false,
      modoOscuro: false,
      agendas:[],
      ultima_lectura_agendas: 0,
      agenda_vista:[], //almacena la ultima vista seleccionada para esa agenda
      agenda: null,
      fecha: null,
      num_dias: 1,
      intervalos:[],//array de objetos. Cada objeto corresponde a un dia que contiene los intervalos de ese día
      citas: [], //citas recuperadas para la vista nueva: cada posición contiene un array las citas de un dia
      colisiones: [], //cada posicion contiene un array con las colisiones de ese día. Se calcular dentro de cada dia pero que esten aqui para resetear cada vez que recargo las citas
      //citas_rendered: 0,
      marcas:[], //contiene un array con las marcas de cada día de la semana en una posición del array 
      vista: 'clasica', //Selección del usuario(clasica nueva columna)
      varias_agendas: false,
      agendas_dibujo: [],
      // modo_efectivo: 'clasica', //clasica, nueva, horas (cuando se visualiza varias agendas a la vez)
      modo_efectivo: 'clasica', //clasica, nueva, columna
      opcion: 'euro', //euro, candado, urgencia
      mostrar_anuladas: false,
      mostrar_noacude: "1",
      mostrar_mayusculas: false,
      mostrar_datos_cita: true,
      mostrar_hora_inicio: false,
      mostrar_hora_fin: false,
      parametros:[],
      ultima_lectura_parametros: 0,
      marcadores:[],
      marcador:null,
      motivosAnulacionCita:[],
      personas: [],
      ultima_lectura_personas: 0,
      rutaActual: 'agenda',
      nueva_persona: false,
      nombre_base: '',
      ultima_actualizacion:'',
      ancho_pantalla: 0,
      contador_consulta: 0,
      // ancho_dia: 0,
      // contador_inactividad: 0,
      // citaEdit_visible: false,
      citaEdit_visible: false,
      opciones_visible: false,
      es_medicont() {return (this.nombre_base==="medicont")},
      es_insight() {return (this.nombre_base==="insight")},
      es_medico() {return (this.nombre_base==="insight"||this.nombre_base==="medicont")},
    },
    empresas: [],
  },

  computed: {
    
  },

  mutations: {
    mostrarLoading(state, texto) {
      state.cargando.texto = texto;
      state.cargando.estado = true;
    },
    ocultarLoading(state) {
      state.cargando.estado = false 
    },
    mostrarMensaje(state,texto) {
      state.mensaje.texto = texto
      state.mensaje.isError=false
      state.mensaje.isWarning=false 
      state.mensaje.estado = true 
      state.mensaje.color= 'blue darken-4'
    },
    mostrarWarning(state,texto) {
      state.mensaje.texto = texto
      state.mensaje.isError=false
      state.mensaje.isWarning=true 
      state.mensaje.estado = true 
      state.mensaje.color= 'purple'
    },
    mostrarError(state,error) {
      // console.log("error")
      // console.log(error)
      let texto;
      if (typeof error === 'string') texto = error;
      else if (error.response!==undefined && error.response!=='' && error.response.data!==undefined && error.response.data!=="") texto=error.response.data;
      else texto = error.message;

      if (texto.indexOf("NOSESION")>=0) {
        this.commit('cerrarSesion',false)
        return;
      } 
      if (texto.indexOf("401")>=0) {
        this.commit('cerrarSesion')
        return;
      } 
      if (texto.indexOf("403")>=0) {
        texto=i18n.t('operacionnopermitida');
      };
      state.mensaje.texto = texto
      state.mensaje.isError=true
      state.mensaje.isWarning=false 
      state.mensaje.estado = true 
      state.mensaje.color='red'
    },
    
    async iniciaSesion(state,objeto) { //Se llama despues de autenticar y despues de recuperar de storage
      state.sesion.tag = objeto.tag;
      state.sesion.cod_usuario = objeto.cod_usuario;
      state.sesion.nombre_usuario = objeto.nombre_usuario;
      state.sesion.num_empresas = objeto.num_empresas;
      state.sesion.nombre_base = objeto.nombre_base;
      state.sesion.mostrar_autenticacion = false;
      // console.log('inicio sesion. establezco agenda:'+ objeto.agenda)
      if (objeto.agenda) { //Esto significa que estoy recargando opciones del storage
        if (objeto.existe) state.sesion.agenda = objeto.agenda; //Solo cargo la agenda cuando existe sesion porque sino cuando se inicia sesion no se dispara el evento de cambio
        // console.log("ancho pantalla:",state.sesion.ancho_pantalla)
        // console.log("objeto.num_dias",objeto.num_dias)
        // if (objeto.num_dias && objeto.num_dias<state.sesion.num_dias) state.sesion.num_dias=objeto.num_dias;
        if (objeto.num_dias) state.sesion.num_dias=objeto.num_dias;
        else {
          if (state.sesion.ancho_pantalla<=600) state.sesion.num_dias=1;
          else if (state.sesion.ancho_pantalla<=960) state.sesion.num_dias=2;
          else if (state.sesion.ancho_pantalla<=1264) state.sesion.num_dias=3;
          else state.sesion.num_dias=7;   
        }

        this.commit('asigna_vista_agenda');
        // //Ajusto la vista segun la ultima seleccionada para esa agenda
        // if (state.sesion.agenda && state.sesion.agenda_vista && state.sesion.agenda_vista[state.sesion.agenda.cod]) {
        //   state.sesion.vista = state.sesion.agenda_vista[state.sesion.agenda.cod];
        // }
        // else if(state.sesion.agenda && state.sesion.agenda.tipo==="S") state.sesion.vista = "clasica"; //agenda de profesional
        // else state.sesion.vista = "columna"; //si no se pudiese aplicar el modo_efectivo devolvera nueva
        // state.sesion.vista=objeto.vista;
        state.sesion.opcion=objeto.opcion;
        state.sesion.mostrar_anuladas=objeto.mostrar_anuladas;
        if (Object.hasOwn(objeto,"mostrar_noacude")) {
          state.sesion.mostrar_noacude=objeto.mostrar_noacude;
        }
        else {
          state.sesion.mostrar_noacude="1";
        }
        state.sesion.mostrar_mayusculas=objeto.mostrar_mayusculas;
        state.sesion.mostrar_datos_cita=objeto.mostrar_datos_cita;
        state.sesion.mostrar_hora_inicio=objeto.mostrar_hora_inicio;
        state.sesion.mostrar_hora_fin=objeto.mostrar_hora_fin;
  
      }
      else objeto.existe = true; //le indico que hay sesion
      localStorage.sesion =JSON.stringify(state.sesion);
      // console.log('Grabo sesion en localStorage:'+objeto.tag)
      axios.defaults.headers['X-Custom-Tag'] = objeto.tag;

      state.sesion.mostrar_empresa = false; 
      state.sesion.ultima_lectura_parametros=0; //Para que recargue los parametros de la empresa
      state.sesion.ultima_lectura_agendas = 0;
      state.sesion.ultima_lectura_personas = 0;
      if (objeto.existe) { //Me llaman aunque se está cargando un objeto sesion con la sesion cerrada
        if (objeto.mostrar_empresa) {
          const objeto2 = await call_url("/agendab/getEmpresas.php");
          if (objeto2.resul==="OK") {
            // console.log("empresas",objeto2.empresas)
            state.sesion.empresas = objeto2.empresas;
            state.sesion.mostrar_empresa = true; 
          }
          else this.commit('mostrarError',i18n.t(objeto.mensaje));  //throw objeto2.mensaje;
        }
        else {
          //this.commit('getAgendas');  
        }
        state.sesion.existe = true; //lo hago al final para que se muestre la agenda antes de seleccion de empresa  
      }
      else state.sesion.mostrar_autenticacion = true;

    },
    async cerrarSesion(state,grabarBD){
      if (grabarBD && state.sesion && state.sesion.tag) {
        //const objeto = (await axios.post(getServidor()+"/agendab/cerrarSesion.php")).data;
        const objeto = await call_url("/agendab/cerrarSesion.php");
        if (objeto.resul!=="OK") { //Si da error al cerrar la sesión muestro mensaje pero continuo
          this.commit('mostrarError',i18n.t(objeto.mensaje)); 
        }  
        //return;
      }
      state.sesion.tag = '';
      state.sesion.existe = false;
      state.sesion.mostrar_logout = false;
      state.sesion.mostrar_autenticacion = true;
      state.sesion.mostrar_empresa = false;
      //localStorage.removeItem('sesion');  
      localStorage.sesion =JSON.stringify(state.sesion);
    },
    async setEmpresa(state,empresa){     
      if (empresa) { //Se ha seleccionado una empresa
        const objeto = await call_url("/agendab/setEmpresa.php",{empresa:empresa});
        if (objeto.resul==="OK") {
          //this.commit('getAgendas');  
          state.sesion.empresa = empresa; //solo se usa para calcular el servidor al que atacar según sea par o impar
          state.sesion.mostrar_empresa = false;
          state.sesion.nombre_base = objeto.nombre_base;
          state.sesion.cod_usuario = objeto.cod_usuario;//cuando el usuario tiene varias empresas hasta que no haces setEmpresa no sabes el codigo de usuario dentro de la empresa
          state.sesion.nombre_usuario = objeto.nombre_usuario;
          state.sesion.agenda= null;
          state.sesion.intervalos = [];
          state.sesion.citas = []
          state.sesion.marcas = [];
          state.sesion.varias_agendas = false;
          state.sesion.agendas_dibujo = [];
          state.sesion.ultima_lectura_parametros = 0;
          state.sesion.ultima_lectura_personas = 0;
          state.sesion.ultima_lectura_agendas = 0;
          localStorage.sesion =JSON.stringify(state.sesion);
          }
        else this.commit('mostrarError',i18n.t(objeto.mensaje));  //throw objeto2.mensaje;
      }
      else { //Mostramos la seleccion de empresa
        const objeto = await call_url("/agendab/getEmpresas.php");
        // console.log(objeto)
        if (objeto.resul==="OK") {
          state.sesion.mostrar_empresa = true;
          state.sesion.empresas = objeto.empresas;
        }
        else this.commit('mostrarError',i18n.t(objeto.mensaje)); 

      }
    },
   
    setIntervalos(state,objeto) {
      state.sesion.modo_efectivo = objeto.modo_efectivo;
      state.sesion.varias_agendas = (objeto.varias_agendas==1);
      state.sesion.intervalos = [...objeto.intervalos];        
      state.sesion.marcas = [...objeto.marcas];
      state.sesion.ultima_actualizacion = objeto.ultima_actualizacion;
    },

    setCitas(state,objeto) {
      state.sesion.citas = [...objeto.citas];
      //Reseteo el array de colisiones
      state.sesion.colisiones = new Array();
      for (let i=0; i<objeto.citas.length; i++) {
        state.sesion.colisiones.push(new Array());
      }

      // console.log("citas recuperadas")
      // console.log(objeto.citas)
      // console.log("agendsa",objeto.agendas)
      state.sesion.agendas_dibujo = (objeto.agendas) ? [...objeto.agendas] : null;

    },

    
    setAgendas(state,agendas) {
      state.sesion.agendas = [...agendas];
      state.sesion.agenda= JSON.parse(localStorage.sesion).agenda
      state.sesion.ultima_lectura_agendas=(new Date()).getTime(); //Para que no recargue en las próximas lecturas
    },

    setParametros(state,parametros) {
      state.sesion.parametros = [...parametros];
      state.sesion.ultima_lectura_parametros=(new Date()).getTime(); //Para que no recargue en las próximas lecturas
    },
    setPersonas(state,personas) {
      // console.log("setPersonas",[...personas])
      state.sesion.personas = [...personas];
      state.sesion.ultima_lectura_personas=(new Date()).getTime(); //Para que no recargue en las próximas lecturas
    },
    asigna_vista_agenda(state) {
        //Ajusto la vista segun la ultima seleccionada para esa agenda
        if (state.sesion.agenda && state.sesion.agenda_vista && state.sesion.agenda_vista[state.sesion.agenda.cod]) {
          // console.log("vista cacheada:"+state.sesion.agenda_vista[state.sesion.agenda.cod]+" se la asigno")
          state.sesion.vista = state.sesion.agenda_vista[state.sesion.agenda.cod];
        }//sino la tengo almacenada la asigno segun el tipo de agenda
        else if(state.sesion.agenda && state.sesion.agenda.tipo==="S") state.sesion.vista = "clasica"; //agenda de profesional
        else state.sesion.vista = "columna"; //si no se pudiese aplicar el modo_efectivo devolvera nueva

    },
    atras_fecha(state){
      state.sesion.fecha = date_to_char(date_add(char_to_date(state.sesion.fecha),-state.sesion.num_dias,"dia"),"yyyy-mm-dd");
    },
    adelante_fecha(state){
      state.sesion.fecha = date_to_char(date_add(char_to_date(state.sesion.fecha),state.sesion.num_dias,"dia"),"yyyy-mm-dd");
    },

    
  },
  modules: {
  },

  actions:{

    async getCitasIntervalos({commit, state, dispatch}){
      if (!state.sesion.existe || !state.sesion.agenda) return;
      state.sesion.contador_consulta = 0;
      await dispatch('getIntervalos');
      if (state.sesion.agenda.tipo!=="S" || state.sesion.vista!=="clasica") 
        await dispatch('getCitas'); //Las citas solo las vista nueva y columnas. En la vista clásica las citas van en los intervalos
    },

    async getIntervalos({commit, state}){  
      state.sesion.contador_consulta = 0;
      if (!state.sesion.agenda || !state.sesion.fecha) return;
      axios.defaults.headers['X-Custom-Tag']=state.sesion.tag;
      localStorage.sesion =JSON.stringify(state.sesion);
     
      try{             
        commit('mostrarLoading',i18n.t('leyendodatos')+'...');
        // console.log("llamo a getIntervalos")
        const objeto = await call_url("/agendab/getIntervalos.php",{'agenda': state.sesion.agenda.cod,vista:state.sesion.vista,'fecha_inicio':state.sesion.fecha, 'num_dias':state.sesion.num_dias,mostrar_datos_cita: state.sesion.mostrar_datos_cita });
        // console.log("getIntervalos",objeto)
        if (objeto.resul==="OK") { 
          // console.log("le paso "+state.sesion.vista+" y devuelve "+objeto.modo_efectivo)
          // state.sesion.modo_efectivo = objeto.modo_efectivo;
          // state.sesion.varias_agendas = (objeto.varias_agendas==1);
          // state.sesion.intervalos = [...objeto.intervalos];        
          // state.sesion.marcas = [...objeto.marcas];
          // state.sesion.ultima_actualizacion = objeto.ultima_actualizacion;
          commit('setIntervalos',objeto);
        }
        else commit('mostrarError',i18n.t(objeto.mensaje));
      }
      catch(error) {
        console.error(error);
        commit('mostrarError',error);
      }
      finally {
        commit('ocultarLoading');
      }
    },

    async getCitas({commit, state}){  //getCitas se llama solo para la vista nueva
      state.sesion.citas = [];
      commit('setCitas',{citas:[],agendas_dibujo:[]});
      if (!state.sesion.agenda || !state.sesion.fecha) return;
      axios.defaults.headers['X-Custom-Tag']=state.sesion.tag;
      localStorage.sesion =JSON.stringify(state.sesion);
    
      try{             
        commit('mostrarLoading',i18n.t('leyendodatos')+'...');
        const objeto = await call_url("/agendab/getCitas.php",{'agenda': state.sesion.agenda.cod,'fecha_inicio':state.sesion.fecha, 'num_dias':state.sesion.num_dias,mostrar_datos_cita: state.sesion.mostrar_datos_cita});
        // console.log("getCitas",objeto)
        if (objeto.resul==="OK") { 
          commit('setCitas',objeto);
        }
        else commit('mostrarError',objeto.mensaje);
      }
      catch(error) {
        console.log(error);
        commit('mostrarError',error.response.data);
      }
      finally {
        this.commit('ocultarLoading');
      }
    },

  }
  
 
})
