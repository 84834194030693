<template>
  <v-row justify="center">
    <Confirm ref="confirmaEliminar" :label="$t('estaseguroeliminarcita')"  :label_aceptar="$t('eliminar')" icon_aceptar="delete" color_aceptar="error" @confirm="eliminar2" />
    <Confirm ref="confirmaGrabar" :label="$t('citapresentaproblemas')" :cuerpo="problemas_grabar_cita" :label_aceptar="$t('grabar')" icon_aceptar="save" color_aceptar="primary" @confirm="grabar(true);" />
    <PersonaEdit ref="personaEdit" />
    <v-dialog persistent max-width="600" v-model="sesion.citaEdit_visible">
      <v-card>
        <v-card-title class="cabeceraModal">
         
        </v-card-title>
          
        <v-card-text >
          <v-form v-model='formValid' ref='form' onsubmit='return false' >
            <v-row dense>

              <!--AGENDA-->
              <v-col v-if="getParametro('citaVariasAgendas')!=='S'" cols="6" class="margintop_20">
                   <v-autocomplete v-model="rec.agenda" :rules="[rules.agenda]" id="persona" :items="lista_agendas" return-object  item-text="nombre" 
                       :label="'* '+$t('agenda')" :disabled="cita_disabled"  class="autocompleto" required dense :no-data-text="$t('noexisteningunaagenda')"/>
              </v-col>
              <v-col v-if="getParametro('citaVariasAgendas')==='S'" cols="6" class="margintop_20">
                   <v-select v-model="rec.agendas" :rules="[rules.agendas]" :items="lista_agendas" return-object item-text="nombre" multiple small-chips 
                       :label="'* '+$t('agenda')" :disabled="cita_disabled" class="autocompleto" required  dense :no-data-text="$t('noexisteningunaagenda')"/>
              </v-col>
           

              <!--TIPO_CITA-->
              <v-col cols="6" class="margintop_18"><v-select v-model="rec.tipo_cita"  :disabled="es_update && rec.tipo_cita==='C'" :rules="[rules.tipo_cita]"  :items="tipos_cita" item-value="cod" @change="tipo_cita_change" :label="'* '+$t('tipocita')">
                <template v-slot:selection="{ item }">
                    <img :src="require('@/assets/'+item.image)" class="mr-1">{{ acortado_tipo_cita(item.cod) }}
                </template>
                <template v-slot:item="{ item }">
                    <img :src="require('@/assets/'+item.image)" class="mr-2">{{ item.texto }}
                </template>
              </v-select>

              </v-col>
            </v-row>
            <!--PERSONA-->
            <!-- <v-row v-if="rec.tipo_cita==='C'" dense> 
              <v-col cols="10" class="margintop_20">
                <v-autocomplete  v-model="rec.persona" :disabled="!paciente_enabled" :error-messages="mensaje_persona" @focus="persona_foco=true" @input="persona_change" :items="sesion.personas" return-object :item-text="nombreYapellidos" :label="'* '+$t('persona')" 
                     class="autocompleto"  dense :no-data-text="$t('noexisteningunapersona')"/>
                           
              </v-col>
              <v-col cols="1" class="margintop_5" v-if="tienePermiso('PER_GESTIONPACIENTES_CT') && rec.persona">
                 <v-icon class="mr-4" color="blue" @click="editar_persona">contact_phone</v-icon>
              </v-col>
              <PersonaEdit ref="personaEdit" v-model="persona_nueva" @input="persona_nueva_change" />
              <v-col cols="1" class="margintop_5" v-if="es_insert"><v-icon class="mr-4" color="orange" @click="persona_anadir_click">person_add</v-icon></v-col>
            </v-row> -->

            <v-row v-if="rec.tipo_cita==='C'" dense> 
              <v-col cols="10" class="margintop_20">
                <ControlAutoComplete  v-model="rec.persona" :disabled="!paciente_enabled" :error-messages="mensaje_persona" @focus="persona_foco=true" @change="persona_change" :items="sesion.personas" :getText="nombreYapellidos" :label="'* '+$t('persona')" 
                     class="autocompleto"  dense :no-data-text="$t('noexisteningunapersona')"/>
                           
              </v-col>
              <v-col cols="1" class="margintop_5" v-if="tienePermiso('PER_GESTIONPACIENTES_CT') && rec.persona">
                 <v-icon class="mr-4" color="blue" @click="editar_persona">contact_phone</v-icon>
              </v-col>
              <PersonaEdit ref="personaEdit" v-model="persona_nueva" @input="persona_nueva_change" />
              <v-col cols="1" class="margintop_5" v-if="es_insert"><v-icon class="mr-4" color="orange" @click="persona_anadir_click">person_add</v-icon></v-col>
            </v-row>

            <v-row v-if="rec.tipo_cita==='C'" dense>
              <!--ACTO,  URGENCIA-->
              <v-col v-if="this.sesion.es_medico()" cols="7" class="margintop_20"><v-autocomplete v-model="rec.acto" :clearable="!acto_obligatorio" :error-messages="mensaje_acto" :label="etiqueta_acto" @change="acto_change" @focus="acto_foco=true" :items="actos" return-object item-text="nombre"  
                         :disabled="cita_disabled" class="autocompleto" required  dense :no-data-text="$t('noexisteningunacto')"/>
              </v-col>
              <v-col cols="5" class="margintop_20">
                <v-select v-model="rec.urgencia" :items="urgencias" item-value="cod" :label="'* '+$t('urgencia')" :disabled="cita_disabled">
                  <template v-slot:selection="{ item }">
                      <img :src="require('@/assets/'+item.image)" class="mr-2">{{ item.texto }}
                  </template>
                  <template v-slot:item="{ item }">
                      <img :src="require('@/assets/'+item.image)" class="mr-2">{{ item.texto }}
                  </template>
                </v-select>
              </v-col>

            </v-row>
            <v-row dense>
              <v-col v-if="rec.tipo_cita!=='C'" cols="6" class="margintop_30"><v-checkbox v-model="rec.todoeldia" :label="$t('todoeldia')"/> </v-col>
            </v-row>
            <!--FECHA  HORA  DURACION-->
            <v-row dense>
              <v-col cols="4" sm="3" class="margintop_21"><ControlFecha :label="'* '+$t('fecha')" v-model="rec.fecha" :rules="[rules.fecha]" :disabled="cita_disabled" :clearable="false" class="fecha ml-2 mt-2" :mostrar_icono="sesion.ancho_pantalla>=350" @change="recalculaAviso(false)" /></v-col>
              <v-col cols="1"></v-col>
              <v-col v-if="!rec.todoeldia" cols="2" sm="3" class="margintop_21"><ControlHora :label="'* '+$t('hora')" v-model="rec.hora" :rules="[rules.hora]" :disabled="cita_disabled" :clearable="false" class="fecha" :mostrar_icono="sesion.ancho_pantalla>=350" @change="recalculaAviso(false)" /></v-col>
              <v-col cols="1"></v-col>
              <!-- <v-col v-if="!rec.todoeldia" cols="5" sm="4" class="margintop_13"><v-text-field v-model="rec.duracion" type="text" :label="'* '+$t('duracion')" :rules="[rules.duracion,rules.multiplo5]" append-outer-icon="add" @click:append-outer="masDuracion" prepend-icon="remove" @click:prepend="menosDuracion" /></v-col> -->
              <v-col v-if="!rec.todoeldia" cols="4" sm="4" class="margintop_13"><ControlDuracion :label="'* '+$t('duracion')" v-model="rec.duracion" :rules="[rules.duracion,rules.multiplo5]" :disabled="cita_disabled" /></v-col>              
            </v-row>
         
            <v-row  dense>
            </v-row>
            <v-row dense>

              <!--RECURSO-->
              <v-col v-if="(rec.tipo_cita==='C' || rec.tipo_cita==='R') && getParametro('EXISTEN_RECURSOS')==='S' && getParametro('citaVariosRecursos')!=='S'" cols="7" class="margintop_20">
                   <v-autocomplete v-model="rec.recurso" :items="recursos" return-object item-text="nombre" :clearable="!recurso_obligatorio" :error-messages="mensaje_recurso" @focus="recurso_foco=true" :label="etiqueta_recurso" 
                           class="autocompleto" required  dense :no-data-text="$t('noexisteningunrecursodisponible')" :disabled="cita_disabled"/>
              </v-col>
              <v-col v-if="(rec.tipo_cita==='C' || rec.tipo_cita==='R') && getParametro('EXISTEN_RECURSOS')==='S' && getParametro('citaVariosRecursos')==='S'" cols="7" class="margintop_20">
                   <v-select v-model="rec.recursos" :items="recursos" return-object item-text="nombre" multiple small-chips :clearable="!recurso_obligatorio" :error-messages="mensaje_recurso" @focus="recurso_foco=true" :label="etiqueta_recurso" 
                           class="autocompleto" required dense :no-data-text="$t('noexisteningunrecursodisponible')"/>
              </v-col>

              <!-- ESTADO -->
              <v-col v-if="rec.tipo_cita==='C'" cols="5" class="margintop_20">
                <v-select v-model="rec.estado" :items="estados" item-value="cod" :label="'* '+$t('estado')" @change="recalculaAviso(false); rec.motivoAnulacion=null;">
                  <template v-slot:selection="{ item }">
                      <img v-if="sesion.ancho_pantalla>360" :src="require('@/assets/'+item.image)" class="mr-2">{{ item.texto }}
                  </template>
                  <template v-slot:item="{ item }">
                      <img :src="require('@/assets/'+item.image)" class="mr-2">{{ item.texto }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row dense> 
              <v-col cols="8" sm="9" class="margintop_13">
                <v-textarea v-model="rec.descripcion" :error-messages="mensaje_descripcion" outlined rows="1" :label="$t('datoscita')" />
              </v-col>
              <v-col cols="1"/>
              <v-col cols="3" sm="2" class="margintop_18 texto_pequeno">
                {{$t('colorcita')}} 
                <ControlColor v-model="rec.color"  :label="$t('colorcita')" />
              </v-col>
            </v-row>

            <v-row dense v-if="rec.tipo_cita==='C'"> 
              <v-col cols="7" class="margintop_18">
              <ControlAviso v-model="rec" @autorizacionCambiada="autorizacionCambiada" :info="rec.getInformacionAvisos()" :enabled="puedo_abrir_avisos" 
                      :colorInfo="rec.getColorAvisos()" @click="editarAviso" ref='aviso' :label="$t('veravisos')" />
              </v-col>
              <v-col cols="5" class="margintop_30" v-if="rec.estado==='A' && sesion.motivosAnulacionCita.length>0">

              <v-autocomplete v-model="rec.motivoAnulacion"  :label="$t('motivoanulacion')" :items="sesion.motivosAnulacionCita" return-object item-text="nombre"  
                         class="autocompleto"  dense :error-messages="mensaje_motivoAnulacion" />
               
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>      
        <v-row>
            <v-col cols="4" sm="3"><v-btn color="primary" @click="grabar" :class="{'ml-5':sesion.ancho_pantalla>=500}" :disabled="!formValid2" small><v-icon >save</v-icon>{{$t('grabar')}}</v-btn></v-col>
            <v-col cols="4" sm="3" v-if="es_update"><v-btn color="error" @click="eliminar" :class="{'ml-5':sesion.ancho_pantalla>=500}" small ><v-icon >delete</v-icon>{{$t('borrar')}}</v-btn></v-col>
            <v-col cols="4" sm="3" v-if="es_update"><v-btn color="orange" @click="duplicar" :class="{'ml-5':sesion.ancho_pantalla>=500}" small ><v-icon >content_copy</v-icon>{{$t('copiar')}}</v-btn></v-col>
            <v-col cols="4" sm="3"><v-btn color="gray" @click="cerrar" :class="{'ml-5':sesion.ancho_pantalla>=500}" small><v-icon color="red">arrow_back_ios</v-icon>{{$t('cerrar')}}</v-btn></v-col>
        </v-row>
        <br/>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {mapState, mapMutations, mapGetters } from 'vuex';
// import ControlAutocomplete from '@/components/ControlAutocomplete.vue';
import ControlAutoComplete from '@/components/ControlAutoComplete.vue';
import ControlAviso from '@/components/ControlAviso.vue';
import ControlFecha from '@/components/ControlFecha.vue';
import ControlHora from '@/components/ControlHora.vue';
import ControlDuracion from '@/components/ControlDuracion.vue';
import ControlColor from '@/components/ControlColor.vue';
import Confirm from '@/components/Confirm.vue';
import PersonaEdit from '@/components/PersonaEdit.vue';
import {call_url, getParametro, to_number, is_numeric, tienePermiso,char_to_date,date_to_char,Agenda,Cita,Persona, getPersonas, mostrarError} from '@/library/misFunciones.js';

export default {
    name: 'CitaEdit',   
    components: { ControlAutoComplete, ControlAviso, ControlFecha ,ControlHora, ControlDuracion, ControlColor,Confirm,PersonaEdit},

    computed: {
        ...mapState(['sesion']),
        ...mapGetters(['parametro']),
        tipo_cita() { return this.rec.tipo_cita; },
        lista_agendas() {return this.sesion.agendas.filter( (agenda) => agenda.tipo==="S" );},
        agenda() { return this.rec.agenda; },
        agendas() { return this.rec.agendas; },
        acto()   { return this.rec.acto;   },
        fecha() {return this.rec.fecha},
        hora() {return this.rec.hora},
        duracion() {return this.rec.duracion},
             
        acto_obligatorio(){
          return (getParametro("actoMedicoObligatorio")=="S");
        },
        mensaje_acto(){
          if (this.rec.tipo_cita==="C" && !this.rec.acto && this.acto_foco && this.acto_obligatorio) {
            return this.$i18n.t('seleccioneacto');
          }
          return "";
        },
        etiqueta_acto() {
          let resul="";
          if (this.acto_obligatorio) resul ='* '
          return resul + this.$i18n.t('acto');
          
        },
        seleccionada_agenda(){
          if (getParametro("citaVariasAgendas")=="S") { //Agendas multiples
            if (this.rec.agendas && this.rec.agendas.length>0) return true;
          }
          else if (this.rec.agenda) return true;
          return false;
        },

        recurso_obligatorio(){
          return (getParametro("agendaRecursoObligatoria")=="S");
        },
        seleccionado_recurso(){
          if (getParametro("citaVariosRecursos")=="S") { //Recursos multiples
            if (this.rec.recursos && this.rec.recursos.length>0) return true;
          }
          else if (this.rec.recurso) return true;
          return false;
        },
        mensaje_recurso(){
          if ((this.rec.tipo_cita==="C"||this.rec.tipo_cita==="R") && this.recurso_foco && this.recurso_obligatorio && !this.seleccionado_recurso) {
            return this.$i18n.t('seleccionerecurso');
          }
          return "";
        },
        etiqueta_recurso() {
          let resul="";
          if (this.recurso_obligatorio) resul ="* ";
          return resul + this.$i18n.t("recurso");
          
        },
        mensaje_descripcion(){
          if (this.rec.tipo_cita!=="C" && this.rec.tipo_cita!=="V" && this.rec.descripcion==="")  {
            return this.$i18n.t("introduzcadatoscita");
          }
          return "";
        },
        mensaje_motivoAnulacion() {
          if (this.rec.estado==="A" && this.sesion.motivosAnulacionCita.length>0 && !this.rec.motivoAnulacion) {
            return this.$i18n.t("introduzcamotivoanulacion")
          }
          return "";
        },
        formValid2(){

          if (!this.formValid) return false;
          if (this.rec.tipo_cita==="C" && !this.rec.persona) return false;
          if (this.rec.tipo_cita==="C" && !this.rec.acto && this.acto_obligatorio) return false;
          if ((this.rec.tipo_cita==="C"||this.rec.tipo_cita==="R") && this.recurso_obligatorio && !this.seleccionado_recurso) return false;
          if (this.rec.tipo_cita!=="C" && this.rec.tipo_cita!=="V" && !this.rec.descripcion) return false;
          if (this.rec.estado==='A' && this.sesion.motivosAnulacionCita.length>0 && !this.rec.motivoAnulacion) return false;
          return true;
        },
        puedo_abrir_avisos(){
          if (this.rec.tipo_cita==="C" && this.rec.persona && this.rec.fecha && this.rec.hora && this.seleccionada_agenda) return true;
          return false;
        },
        cita_disabled() {
          return (this.estado_anterior==="E" || this.estado_anterior==="T" || this.estado_anterior==="N");
        },
        paciente_enabled() {
          return (!this.es_update || (this.estado_anterior==="P" || this.estado_anterior==="D"));
        }
      
    },
    data() {
        return {
            cargando:false,
            es_insert: false,
            es_update: false,
            estado_anterior: 'P',
            rec: new Cita({agenda:null,tipo_cita:'C',persona:null,acto:null,'fecha':'',todoeldia: false,'hora':'','duracion':'',recurso:null,urgencia:null,estado:null,descripcion:'',color:'#000000'}),          
            actos: [],
            persona_nueva: null,
            urgencias:[{cod:'N',texto:this.$i18n.t('normal'),image:'alertaVerde2.png'},{cod:'A',texto:this.$i18n.t('algourgente'),image:'alertaNaranja2.png'},{cod:'M',texto:this.$i18n.t('muyurgente'),image:'alertaRoja2.png'}],
            estados: [{cod:'P',texto:this.$i18n.t('pendiente'),image:'paciente.gif'},{cod:'D',texto:this.$i18n.t('dudosa'),image:'pacienteDudoso.gif'},
                      {cod:'E',texto:this.$i18n.t('enespera'),image:'pacienteEspera.gif'},{cod:'T',texto:this.$i18n.t('atendida'),image:'atendida.png'},
                      {cod:'A',texto:this.$i18n.t('anulada'),image:'pacienteAnulado.gif'},{cod:'N',texto:this.$i18n.t('noacude'),image:'pacienteTachado.gif'}],
            recursos:[],
            timerGetRecursos: null,
            tipos_cita: [{'cod':'C','texto':this.$i18n.t('citapaciente'),image:'paciente.gif'},{'cod':'A','texto':this.$i18n.t('asuntopersonal'),image:'personal.gif'},
                         {'cod':'V','texto':this.$i18n.t('vacaciones'),image:'vacaciones.gif'},{'cod':'F','texto':this.$i18n.t('formacion'),image:'formacion.gif'},
                         {'cod':'R','texto':this.$i18n.t('reunion'),image:'reunion.gif'} ],
            selected: undefined,
            formValid: false,
            persona_foco: false,
            acto_foco: false,
            recurso_foco: false,
            problemas_grabar_cita: '',
            mensaje_persona:'',
            rules: {
                agenda:    v => (!!v) || this.$i18n.t('seleccioneagenda'),
                agendas:    v => (!!v && v.length>0)|| this.$i18n.t('seleccioneagenda'),
                tipo_cita: v => !!v || this.$i18n.t('seleccionetipocita'),
                fecha: v => v || this.$i18n.t('fechaincorrecta'),
                hora: v => v.length===5 || this.$i18n.t('horaincorrecta'),
                duracion: v => (is_numeric(v) && to_number(v)>0) || this.$i18n.t('introduzcaduracion'),
                multiplo5: v => ((to_number(v) % 5) ==0) || this.$i18n.t('duracionmultiplo5'),
            },

        }
    },
    methods: {
        tienePermiso,
        getParametro,
        getPersonas,
        ...mapMutations(['mostrarLoading','ocultarLoading','mostrarError','mostrarMensaje','mostrarWarning']),

       acortado_tipo_cita(tipo_cita) {

            // tipos_cita: [{'cod':'C','texto':this.$i18n.t('citapaciente'),image:'paciente.gif'},{'cod':'A','texto':this.$i18n.t('asuntopersonal'),image:'personal.gif'},
            //              {'cod':'V','texto':this.$i18n.t('vacaciones'),image:'vacaciones.gif'},{'cod':'F','texto':this.$i18n.t('formacion'),image:'formacion.gif'},
            //              {'cod':'R','texto':this.$i18n.t('reunion'),image:'reunion.gif'} ],

          if (tipo_cita==="C") {
            if (this.sesion.ancho_pantalla>390) return this.$i18n.t('citapaciente');
            else return this.$i18n.t('paciente');
          }
          else if (tipo_cita==="A") {
            if (this.sesion.ancho_pantalla>380) return this.$i18n.t('asuntopersonal');
            else return this.$i18n.t('personal');
          }
          else if (tipo_cita==="V") {
            if (this.sesion.ancho_pantalla>=325) return this.$i18n.t('vacaciones');
            else return this.$i18n.t('vacaci...');
          }
          else if (tipo_cita==="F") return this.$i18n.t('formacion');
          else if (tipo_cita==="R") return this.$i18n.t('reunion');

       },

        // masDuracion() {
        //   if (is_numeric(this.rec.duracion)) {
        //     const resto = (to_number(this.rec.duracion) % 5);
        //     if (resto===0) this.rec.duracion=to_number(this.rec.duracion)+5;
        //     else this.rec.duracion=to_number(this.rec.duracion)+5-resto;
        //   }
        //   else this.rec.duracion=5;
        // },
        // menosDuracion() {
        //   if (is_numeric(this.rec.duracion) && to_number(this.rec.duracion)>=10) {
        //     const resto = (to_number(this.rec.duracion) % 5);
        //     if (resto===0) this.rec.duracion=to_number(this.rec.duracion)-5;
        //     else this.rec.duracion=to_number(this.rec.duracion)-resto;
        //   } 
        //   else this.rec.duracion=5;
        // },
        tipo_cita_change(){
          if (this.rec.tipo_cita==="C") this.rec.todoeldia = false;
        },
        acto_change(){
          //OJO: cuando estoy cargando datos, si el acto lo actualizo despues de la duracion: pierdo la duracion
          if (this.rec.acto && !this.cargando) {
            if (is_numeric(this.rec.acto.duracion)) this.rec.duracion = to_number(this.rec.acto.duracion);
            this.recalculaAviso(true);
          } 
          this.getRecursos2();
        },
        persona_change(){
          if (!this.rec.persona) {
            // console.log("persona vacia")
            this.rec.persona = null;
            this.recalculaAviso(true);
            return;
          }
          // console.log("persona_change",this.rec.persona.aviso)
          if (this.rec.persona && this.rec.persona.aviso && this.rec.persona.aviso!=="") this.mostrarWarning(this.rec.persona.aviso);
          if (this.cargando) return;
          this.rec.persona = new Persona({...this.rec.persona}); //si no lo hago así no me reconoce los métodos de la clase
          this.recalculaAviso(true);
        },
        persona_anadir_click(){

          let agenda=null;
          if (getParametro('citaVariasAgendas')=='S') {
            if (this.rec.agendas && this.rec.agendas.length>0) agenda = this.rec.agendas[0];
          }
          else agenda = this.rec.agenda
          this.$refs.personaEdit.nuevo(agenda);
        },
        persona_nueva_change(){
          this.sesion.personas.push({...this.persona_nueva});
          this.rec.persona=this.persona_nueva;         
          this.rec = new Cita({...this.rec});
          this.recalculaAviso(true);       
        },
        editar_persona() {
          this.$refs.personaEdit.editar(this.rec.persona.cod);
        },
        autorizacionCambiada(autorizacion) { //Autorizacion de la persona cambiada. Me tiene que llegar hasta aqui para grabarlo en rec.persona.autorizacionAviso porque sino, si le doy a cancelar desde la ControlAviso no mantiene el valor grabado desde ControlAutorizacion
          this.rec.persona.autorizacionAviso = autorizacion;
        },

        nombreYapellidos(persona) {
          // console.log("nombreYapellidos:persona",persona)
          if (persona===null)
            return "";
          else 
            return persona.nombre+" "+persona.apellidos;
        },

        editarAviso(){
          // console.log(this.rec)
          if (this.sesion.es_medico() && !tienePermiso('PER_GESTIONPACIENTES_CT')) this.mostrarError(this.$i18n.t("notienepermisoconsultaravisos"))
          else this.$refs.aviso.editar(this.rec);
        },

        async editar(cod){
          console.log("empresa",this.sesion.empresa)
          try{
            this.cargando = true;
            this.getPersonas();
            this.getRecursos();
            const data = await call_url("/agendab/getCita.php",{"cod":cod});
            // console.log("citaEdit.editar data",data)
            if (data.resul==="OK") {
                this.rec = new Cita(data.cita);
                if (this.rec.persona && this.rec.persona.aviso && this.rec.persona.aviso!=="") this.mostrarWarning(this.rec.persona.aviso);
                // console.log("citaEdit.editar this.rec",this.rec)
                // console.log("cita",data.cita);
                // console.log("cita2",this.rec);
                this.estado_anterior = this.rec.estado;
                this.sesion.citaEdit_visible = true;
                this.es_insert = false;
                this.es_update = true
                setTimeout(this.foco,100);                
            }
            else this.mostrarError(this.$i18n.t(data.mensaje));

          }
          catch(error) {
            console.error(error);
            this.mostrarError(error);
          }
          finally {
            this.cargando = false;
          }

        },

        foco() {
           this.persona_foco = false;
           this.acto_foco = false;
           this.$refs.form.resetValidation();
        } ,
        nuevo(hora,wagenda) {
            try{
              this.cargando = true;
              this.getPersonas();
              this.getRecursos();
              const wfecha=date_to_char(char_to_date(hora.inicio),"yyyy-mm-dd");
              const whora=date_to_char(char_to_date(hora.inicio),"hh:mi");
              let agenda=null,agendas=[];
              if (wagenda && wagenda.tipo==="S") { //Si no es agenda de profesional no la asigno al control 
                if (getParametro("citaVariasAgendas")==="S") {
                  agendas = [wagenda];
                }
                else agenda = wagenda;
              }
              let recurso=null,recursos=[];
              if (wagenda && wagenda.tipo==="R") { //Si no es agenda de recurso no la asigno al recurso
                if (getParametro("citaVariosRecursos")==="S") {
                  recursos = [wagenda];
                }
                else recurso = wagenda;
              }
              let duracion=60;
              // console.log(hora)
              if (hora.duracion) duracion = to_number(hora.duracion);
              //if (this.sesion.)

              this.rec = new Cita({cod:'', agenda:agenda, agendas:agendas, recurso:recurso, recursos:recursos, tipo_cita:'C',persona:null,acto:null,fecha:wfecha,todoeldia:false,hora:whora,duracion:duracion,urgencia:'N',estado:'P',descripcion:'',color:'#000000'});
              this.estado_anterior = 'P';
              this.sesion.citaEdit_visible = true;
              this.es_insert = true;
              this.es_update = false;
              setTimeout(this.foco,100);
            }
            catch(error) {
                console.log(error);
                this.mostrarError(error.message);
            }
            finally {
                //this.ocultarLoading();
                this.cargando=false;
            }

        },
        duplicar(){
            try{
              this.rec.cod = '';
              this.rec.estado = 'P';
              this.estado_anterior = 'P';
              this.rec.avisoEnviado = null;
              this.es_insert = true;
              this.es_update = false;
              this.recalculaAviso(true);
            }
            catch(error) {
                console.log(error);
                this.mostrarError(error.message);
            }
            finally {
                //this.ocultarLoading();
                this.cargando=false;
            }
        },
        async grabar(confirmacion){ //confirmacion:indica si estamos llamando desde la pantalla de confirmacion en cuyo caso tengo que incluir en los datos confirmacion=SI
                                    //ojo: porque a veces me viene como evento
          try{
            this.mostrarLoading(this.$i18n.t('grabando')+'...');
            if (!confirmacion.target && confirmacion) this.rec.confirmacion="SI";
            // console.log("citaEdit.grabar",this.rec)
            // console.log("graba_cita",this.rec)
            // return;
            const data = await call_url("/agendab/grabaCita.php",this.rec);
            this.rec.confirmacion="";
            if (data.resul==="OK") {
              this.sesion.citaEdit_visible = false;
              this.mostrarMensaje(this.$i18n.t('citagrabadacorrectamente'))
              this.$emit('change')
            }
            else if (data.resul==="WARN") {
              this.problemas_grabar_cita = data.mensaje;
              this.$refs.confirmaGrabar.abrir();
            }
            else {
              // console.log("graba_cita no devuelve ok ni warn",data);
              this.mostrarError(data.mensaje);
            }
            
          }
          catch(ex) {
            console.log(ex);
            //this.mostrarError(error.message);
          }
          finally {
            this.ocultarLoading();
          }

        },
        eliminar(){
          try {
            if (this.rec.tipo_cita==="A" && !tienePermiso('PER_AGENDA_BAJA_CITAS_PERSONALES')) throw new Error(this.$i18n.t("notienepermisobajacitaspersonales"))
            if (this.rec.tipo_cita!=="A" && !tienePermiso('PER_AGENDA_B')) throw new Error(this.$i18n.t("notienepermisobajacitas"))

            this.$refs.confirmaEliminar.abrir();
          }
          catch(ex) {
            this.mostrarError(ex.message);
          }
        },
        async eliminar2(){
          try{
            
            this.mostrarLoading(this.$i18n.t('grabando')+'...');
            const data = await call_url("/agendab/eliminaCita.php",this.rec.cod);
            if (data.resul==="OK") {
              this.sesion.citaEdit_visible = false;
              this.mostrarMensaje(this.$i18n.t('citaeliminada'))
              this.$emit('change')
            }
            else {
              this.mostrarError(data.mensaje);
            }
            
          }
          catch(ex) {
            console.log(ex);
            //this.mostrarError(error.message);
          }
          finally {
            this.ocultarLoading();
          }

        },
        cerrar(){
            this.sesion.citaEdit_visible = false;
        },

        recalculaAviso(cambiandoPersona){          
            if (this.cargando) {
              // console.log("recalculaAviso: cargando -> salgo");
              return;
            }
            this.rec.calculaAvisoAutomatico(cambiandoPersona);
        },

        getRecursos() {
            if (this.timerGetRecursos) clearTimeout(this.timerGetRecursos);
            this.timerGetRecursos = setTimeout(this.getRecursos2,500);
        },

        async getRecursos2(){
            let data;
            // if (getParametro("variasCitasRecurso")==="S"){ //|| (this.acto && this.acto.comprobarOcupacionRecurso!=="S" )) {
            //   this.recursos = this.sesion.agendas.filter( (agenda) => agenda.tipo==="R" );
            //   return;
            // }
            // else  {
              if (!this.rec.fecha || !this.rec.hora || !this.rec.duracion) return;
              //console.log("acto",this.acto)
              const actoComprobarOcupacionRecurso = (!this.acto || this.acto.comprobarOcupacionRecurso==="S");
              data = await call_url("/agendab/getAgendas.php",{tipo:'R',fecha:this.rec.fecha,hora:this.rec.hora,duracion:this.rec.duracion,cod_cita:this.rec.cod,actoComprobarOcupacionRecurso:actoComprobarOcupacionRecurso});
            // }
            if (data.resul==="OK") {
                // console.log("recursos",data.agendas);
                this.recursos = data.agendas;
                if (getParametro("citaVariosRecursos")==="S") {
                  for (let i=0; i<this.rec.recursos.length;i++){
                    if (!Agenda.in_array(this.recursos,this.rec.recursos[i])) {
                      this.mostrarError(this.$i18n.t('recursonodisponibleahora'));
                      this.rec.recursos = [];
                    }
                  }
                }
                else {
                  if (this.rec.recurso && !Agenda.in_array(this.recursos,this.rec.recurso)) {
                    this.mostrarError(this.$i18n.t('recursonodisponibleahora'));
                    this.rec.recurso = null;
                  }
                }
            }
            else this.mostrarError(data.mensaje);
        },
        async getActos(){
            if (this.sesion.es_medico()) {
              let cod_agenda='';

              if (getParametro("citaVariasAgendas")==="S") {
                if (this.rec.agendas.length>0) cod_agenda=this.rec.agendas[0].cod;
              }
              else if (this.rec.agenda) cod_agenda=this.rec.agenda.cod;
              if (cod_agenda) {
                const data = await call_url("/agendab/getActos.php?agenda="+cod_agenda);
                //console.log("actos",data)
                if (data.resul==="OK") {
                  this.actos = data.actos;
                }
                else this.mostrarError(data.mensaje);
              }
            }
        },
   

    },

    watch: {

      async agenda (val) {  
        this.getActos();
      },
      async agendas (val) {  
        this.getActos();
      },
      cargando() {
          if (this.cargando) this.mostrarLoading(this.$i18n.t('leyendodatos')+'...');
          else this.ocultarLoading();
      },
      fecha()  {
        //if (getParametro("variasCitasRecurso")!=="S") this.getRecursos();
        this.getRecursos();
      },
      hora(hora){
        //if (getParametro("variasCitasRecurso")!=="S") this.getRecursos();
        this.getRecursos();
      },
      duracion(val) {
        // if (getParametro("variasCitasRecurso")!=="S") this.getRecursos();
        this.getRecursos();
      },
      tipo_cita(val) {
        if (val!=="C") {
          this.rec.persona=null;
          this.rec.acto=null;
          this.rec.urgencia='N';
          // this.rec.recurso=null;
          // this.rec.recursos=[];
          this.rec.estado='P';          
        };
        if (val!=="C" && val!=="R") {
          this.rec.recurso=null;
          this.rec.recursos=[];
        };

      },

    }

 

}

</script>

<style scope>
.margintop_21 {
  margin-top: -21px;
}
.margintop_20 {
  margin-top: -20px;
}
.margintop_18 {
  margin-top: -18px;
}
.margintop_13 {
  margin-top: -13px;
}
.margintop_5 {
  margin-top: -5px;
}
.margintop_30 {
  margin-top: -30px;
}
.v-select__selections{
  font-size: 12px !important;
  margin-bottom: -5px;
}
.v-label {
  font-size: -5em  !important;
}
.texto_pequeno {
  font-size: 11px;
}


 /* .theme--light.v-select.v-input--is-disabled .v-select__selections, .theme--light.v-select .v-select__selection--disabled {
  color: #606060 !important;
} 
 .theme--light.v-select.v-input--is-disabled .v-select__selections, .theme--light.v-select .v-select__selection--disabled {
  color: rgba(255,0,0,.38) !important;
}
.theme--light.v-select .v-select__selections {
  color: rgba(255,0,0,.87) !important;
}  */
@supports (-webkit-touch-callout: none) {
.theme--light.v-input--is-disabled, .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
   color:#000000 !important; 
}
}

</style>
