import i18n from '../i18n'
import axios from "axios";
import store from '../store';

let miservidor='';
let conta_error_servidor = 0;
let error_en_servidor = false;

const IDIOMA_CASTELLANO = 1;
const IDIOMA_INGLES = 2;
const IDIOMA_PORTUGUES = 3;
const IDIOMA_CATALAN = 4;
const IDIOMA_ALEMAN = 5;
const IDIOMA_EUSKERA = 6;
const IDIOMA_FRANCES = 7;
const IDIOMA_GALLEGO = 8;
const IDIOMA_ITALIANO = 9;


function getServidor() {
    if (miservidor) return  miservidor;
    miservidor = localStorage.getItem('ultimo_servidor');
    if (miservidor) return  miservidor;
    if (((new Date()).getSeconds()%2)==0) miservidor = "https://www.mediconta.es";
    else miservidor = "https://www.grupoinfonet.es";        

    //miservidor = "https://www.mediconta.es";

    localStorage.setItem('ultimo_servidor', miservidor);
    return miservidor;
}
export function entorno_desarrollo() {
   return (process.env.NODE_ENV==="development");
}
export async function call_url(path,datos) {
   // console.log(process.env.NODE_ENV)
   if (entorno_desarrollo()) console.log("llamo a "+getServidor()+path)
   try {
      // try{
      //    console.log("adjunto tag:"+store.state.sesion.tag);
      //    if (datos) datos.tag=store.state.sesion.tag;
      //    else datos = {tag:store.state.sesion.tag}
      // }
      // catch(ex2) {}
      // let headers= {headers:{'X-Custom-Tag':store.state.sesion.tag,'X-Custom-pepe':'holamiamigo'}};
      const objeto = (await axios.post(getServidor()+path,datos)).data;
      if (objeto.mensaje==="NOSESION" || objeto.mensaje==="USUARIONOAUTORIZADOAGENDAMOVIL") {
         store.commit('cerrarSesion',false);
      }
      return objeto;
   } catch(ex) {
      if (conta_error_servidor>5) {
         if (miservidor === "https://www.mediconta.es") miservidor="https://www.grupoinfonet.es";
         else miservidor = "https://www.mediconta.es";

         //miservidor = "https://www.mediconta.es";

         //localStorage.setItem('ultimo_servidor', miservidor);
         conta_error_servidor = 0;
         error_en_servidor = true;
      }
      else conta_error_servidor++;
      throw ex;
   }
}

let leyendo_parametros = false;
async function getParametros() {
   if (leyendo_parametros) return;
   try {

      leyendo_parametros = true;
      //console.log("getParametros")
      const objeto = await call_url("/agendab/getParametros.php");
      if (objeto.resul==="OK") {
         //console.log("compruebo: "+store.state.version)
         // console.log("parametros",objeto)
         if (objeto.parametros[0].tag=="version" && objeto.parametros[0].valor!==store.state.version) {
            console.log("Detectada actualización disponible. Versión actual "+store.state.version+". Nueva versión disponible:"+objeto.parametros[0].valor+". Recargo versión");
            window.location.reload();
         }
         if (objeto.parametros[1].tag=="miservidor" && !error_en_servidor) {
            // console.log("cambio miservidor a (0:mediconta 1:grupoinfonet)",objeto.parametros[1].valor);
            if (objeto.parametros[1].valor==0)
               miservidor = "https://www.mediconta.es";
            else  
               miservidor = "https://www.grupoinfonet.es";
            
            //miservidor = "https://www.mediconta.es";

            localStorage.setItem('ultimo_servidor', miservidor);  
         } 
         if (objeto.parametros[2].tag=="nombreEmpresa") {
            // this.sesion.nombreEmpresa = objeto.parametros[2].valor;
            // localStorage.setItem('nombreEmpresa', objeto.parametros[2].valor);  
            store.state.sesion.nombreEmpresa = objeto.parametros[2].valor;
         }

         store.commit('setParametros',objeto.parametros);
         store.state.sesion.marcadores = [...objeto.marcadores];
         store.state.sesion.motivosAnulacionCita = [...objeto.motivosAnulacionCita];
         // console.log("store.state.sesion.motivosAnulacionCita",store.state.sesion.motivosAnulacionCita)
      }
   } catch(ex) {
      console.error(ex)
   }
   finally {
      leyendo_parametros = false;
   }
};

export function getParametro(tag) {
   let resul="" ;
   for (let i=0;i<store.state.sesion.parametros.length;i++) {
      if (store.state.sesion.parametros[i].tag===tag) resul=store.state.sesion.parametros[i].valor;
   }
   try{
      if ((new Date()).getTime()>store.state.sesion.ultima_lectura_parametros+120000) { //Cada 120 segundos vuelve a leer los parametros
         //console.log("getAgendas y getParametros")
         getParametros();
         //store.commit('getAgendas');
         getAgendas();
      }
   }
   catch(error) {
      console.log(error);
   }

   return resul;
 }


export async function getPersonas() {
   // console.log("getPersonas",store.state.sesion.personas,store.state.sesion.ultima_lectura_personas);
   if ((new Date()).getTime()>store.state.sesion.ultima_lectura_personas+120000) { //Cada 120 segundos vuelve a leer las personas
      // console.log("getPersonas")
      const data = await call_url("/agendab/getPersonas.php");
      // console.log("getPersonas2")
      if (data.resul==="OK") {
         // console.log("getPersonas3",data.personas)
         store.commit('setPersonas',data.personas);         
         //  store.state.sesion.personas = [...data.personas];
         //  store.state.sesion.ultima_lectura_personas=(new Date()).getTime(); //Para que no recargue los parametros en las próximas lecturas
      }
   }
   // return state.sesion.personas
};


let leyendo_agendas = false;
export async function getAgendas(state) {
   if (leyendo_agendas) return;
   leyendo_agendas = true;
   //console.log("llamo a getAgendas4");
   try {
      const objeto = await call_url("/agendab/getAgendas.php");
      if (objeto.resul==="OK") {
         //   state.sesion.agendas = objeto.agendas;
         //   state.sesion.agenda= JSON.parse(localStorage.sesion).agenda
         store.commit('setAgendas',objeto.agendas);
      }
      else store.commit('mostrarError',i18n.t(objeto.mensaje));  //throw objeto2.mensaje;   
   }
   catch(ex) {
      console.log("getAgendas",ex)
   }
   finally {
      leyendo_agendas = false;
   }
 }



export function idioma_to_locale(idioma)  {
   console.log("llego",idioma)
   if (idioma=="" || idioma==1) return "es";
   if (idioma==2) return "en";
   if (idioma==3) return "pt";
   if (idioma==4) return "ca";
   if (idioma==5) return "de";
   if (idioma==6) return "eu";
   if (idioma==7) return "fr";
   if (idioma==8) return "gl";
   if (idioma==9) return "it";
}

export function is_numeric(cad,forzarPositivo=true,forzarInteger=true) {
   if (Number.isInteger(cad)) return true;
   if (isNaN(parseInt(cad))) return false;
   for (let i=0; i<cad.length;i++) {
      //console.log('mini:'+cad.substring(i,i+1))
      if ((cad.substring(i,i+1)<'0' || cad.substring(i,i+1)>'9') && cad.substring(i,i+1)!=="." && (cad.substring(i,i+1)!=="-" || i!==0)   ) return false;
   }
   if (forzarPositivo && to_number(cad)<0) return false;
   if (forzarInteger && parseInt(cad)!==parseFloat(cad)) resul=false;
   return true; 
}

export function to_number(cad) {
   return Number.parseFloat(cad);
}


export function mayuscula_palabra(cad) {
   let splitStr = cad.toLowerCase().split(' ');
   for (let i = 0; i < splitStr.length; i++) {
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   // Directly return the joined string
   return splitStr.join(' '); 
}
export function getIniciales(nombre){
   const cadenas = nombre.split(" "); // SEPARA EL NOMBRE EN CADENAS INDIVIDUALES
   let resul='';
   //document.write("<br/>Iniciales Nombre: ");
   for (let x=0;x<cadenas.length;x++){
       resul += cadenas[x].substring(0, 1);          
   }  
   return resul;
}
export function initCap(nombre) {
   return nombre.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
      return m.toUpperCase();
   });
}
export function quitar_acentos(cad) {
   return cad.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
// export function is_integer(cad) {
//    if (!is_numeric(cad)) return false
//    return (parseInt(cad)==parseFloat(cad));
// }

// export function es_numero_positivo(cad) {
//    const resul= !isNaN(parseInt(cad));
//    console.log("cad:",cad,resul)
//    return resul;
//    return Number.isInteger(cad);
// }

export function num_ocurrencias(cad,patron) {
   if (patron.length===0) return 0;
   let pos=0;
   let num=0;
   while (pos>=0) {
      pos = cad.indexOf(patron,pos);
      if (pos>=0) {
         num++;
         pos += patron.length;
      }
   }
   return num;
}


export function char_to_date(wfecha) {
    let fecha = new Date((typeof wfecha === "string" ? new Date(wfecha) : wfecha).toLocaleString("en-US", {timeZone: 'UTC'}));   
    return fecha
}

//Patron: cadena del tipo dd/mm/yyyy y reemplazo dd mm yyyy
export function date_to_char(wfecha,patron) {
   // console.log(wfecha)
   // console.log(typeof wfecha);
   let fecha;
    if (typeof wfecha ==='string') fecha = char_to_date(wfecha)
    else fecha = wfecha;
   //  console.log(typeof fecha);
    const weekday = new Array(7);  weekday[0] = "domingo"; weekday[1] = "lunes"; weekday[2] = "martes"; weekday[3] = "miercoles"; weekday[4] = "jueves"; weekday[5] = "viernes"; weekday[6] = "sabado";
    let resul = patron.replace('dd', fecha.getDate().toString().padStart(2, '0'));
    resul = resul.replace('mm', (fecha.getMonth()+1).toString().padStart(2, '0'));
    resul = resul.replace('yyyy', fecha.getFullYear().toString());
    resul = resul.replace('aaaa', fecha.getFullYear().toString());
    resul = resul.replace('hh', fecha.getHours().toString().padStart(2,'0'));
    resul = resul.replace('mi', fecha.getMinutes().toString().padStart(2,'0'));
    resul = resul.replace('ss', fecha.getSeconds().toString().padStart(2,'0'));
    resul = resul.replace('day', i18n.t(weekday[fecha.getDay()]));
   //  console.log(resul)
    return resul;
}


export function nombre_dia_semana(wfecha,idioma) {
   let fecha;
    if (typeof wfecha ==='string') fecha = char_to_date(wfecha)
    else fecha = wfecha;
    //  console.log(typeof fecha);
    console.log("nombre_dia_semana",idioma,idioma_to_locale(idioma) )
    const weekday = new Array(7);  weekday[0] = "domingo"; weekday[1] = "lunes"; weekday[2] = "martes"; weekday[3] = "miercoles"; weekday[4] = "jueves"; weekday[5] = "viernes"; weekday[6] = "sabado";
    let resul = i18n.t(weekday[fecha.getDay()],idioma_to_locale(idioma));
    return resul;
}


export function trunca_fecha(fecha) {
   let fecha1 = (typeof fecha === "string" ? char_to_date(fecha) : fecha);
    fecha1.setHours(0,0,0,0);
    return fecha1;
}

//intervalo: dia, hour, minuto, segundo
export function date_add(fecha,numero,intervalo) {
    let fecha2 = new Date(fecha.getTime());;
    if (intervalo=="dia" || intervalo=="day") {
       fecha2.setDate(fecha.getDate() + numero);
       return fecha2;
    }
    if (intervalo=="hora" || intervalo=="hour") {
      fecha2.setHours(fecha.getHours() + numero);
      return fecha2;
   }
    if (intervalo=="minuto" || intervalo=="minute") {
      fecha2.setMinutes(fecha.getMinutes() + numero);
      return fecha2;
   }
   if (intervalo=="second" || intervalo=="segundo") {
      fecha2.setSeconds(fecha.getSeconds() + numero);
      return fecha2;
   }
}
//intervalo: day hour minute second
export function date_diff(wfecha1,wfecha2,intervalo){
   // let fecha1 = (typeof wfecha1 === "string" ? new Date(wfecha1) : wfecha1);
   // let fecha2 = (typeof wfecha2 === "string" ? new Date(wfecha2) : wfecha2);
   let fecha1 = (typeof wfecha1 === "string" ? char_to_date(wfecha1) : wfecha1);
   let fecha2 = (typeof wfecha2 === "string" ? char_to_date(wfecha2) : wfecha2);
   let diffMs = (fecha2 - fecha1); //obtengo milisegundos de diferencia
   if (intervalo==="day") return Math.floor(diffMs / (3600000 * 24)); //days
   if (intervalo==="hour") return Math.floor(diffMs / 3600000); //hours
   if (intervalo==="minute") return Math.round(diffMs / 60000); // minutes   
   if (intervalo==="second") return Math.round(diffMs / 1000); // seconds   
}
export function now(){
   return new Date();	
 }
 
export function es_hoy(fecha) {
    let fecha2;
    if (typeof(fecha)=="date") fecha2 = fecha;
    else fecha2 = char_to_date(fecha);

   return (trunca_fecha(fecha2).getTime()==trunca_fecha(new Date()).getTime());
}



export function tienePermiso(tagPermiso) {
   // console.log("parametros:",store.state.sesion.cod_usuario,store.state.sesion.parametros)
   // console.log("tienePermiso:"+store.state.sesion.cod_usuario+tagPermiso+":"+getParametro(store.state.sesion.cod_usuario+tagPermiso)+"+");
   return (getParametro(store.state.sesion.cod_usuario+tagPermiso)==="S");
};


export function esNumeroMovil(numero) {
   const telefonos=numero.split(",");
   for (let i=0; i<telefonos.length; i++) {
      if (!esNumeroMovilUno(telefonos[i].trim())) return false;
   }
   return true //"";
}
 
function esNumeroMovilUno(cad) {
   if (cad.length!=9) return false; //"Número de teléfono móvil debe tener 9 dígitos";
   for (let i=0;i<=8;i++) {
     if (cad.substring(i,i+1)<'0' || cad.substring(i,i+1)>'9') return false; //"Número de teléfono móvil debe ser numérico";
   }
   if (cad.substring(0, 1)!="6" && cad.substring(0, 1)!="7") return false; //"Número de teléfono móvil debe empezar por 6 o 7";
   return true; //"";
}

export function esEmail(email) {
   const emails=email.split(",");
   for (let i=0; i<emails.length; i++) {
     if (!esEmailUno(emails[i].trim())) return false;
   }
   return true;
}
function esEmailUno(cad) {
     cad = cad.trim();
     if (cad.length< 5) return false;
     const posarroba=cad.indexOf("@");
     if (posarroba< 2) return false;
     if (cad.indexOf(".",posarroba) < posarroba + 2) return false;
     if (cad.substring(cad.length-1)===".") return false;
     if (cad.indexOf(" ")>=0) return false;
     if (cad.indexOf(",")>=0) return false;
     if (cad.indexOf(";")>=0) return false;
     if (cad.indexOf("/")>=0) return false;
     if (cad.indexOf("\\")>=0) return false;
     return true;
}
export function getImagen_tipocita(tipo_cita,estado) {
   let imagen="";
   if (tipo_cita=="C") {
       if (estado=="P") imagen='paciente.gif';
       else if (estado=="D") imagen='pacienteDudoso.gif';
       else if (estado=="E") imagen='pacienteEspera.gif';
       else if (estado=="T") imagen='atendida.png';
       else if (estado=="A") imagen='pacienteAnulado.gif';
       else if (estado=="N") imagen='pacienteTachado.gif';
   }
   else if (tipo_cita=="A") imagen="personal.gif";
   else if (tipo_cita=="V") imagen="vacaciones.gif";
   else if (tipo_cita=="F") imagen="formacion.gif";
   else if (tipo_cita=="R") imagen="reunion.gif";

   if (imagen!=="") return require('@/assets/'+imagen);
   else return null;
}
export function getImagen_opcion(tipo_cita,pagado,rgpd,urgencia,opcion) {
   let imagen='';
   if (tipo_cita=='C') {
       if (opcion=='euro') {
           if (pagado) imagen='euroVerde2.png';
           else imagen='euroRojo2.png';
       }
       else if (opcion=='rgpd') {
           if (rgpd) imagen='candadoVerde2.png';
           else imagen='candadoRojo2.png'
       }
       else if(opcion=='urgencia') {
           if (urgencia=='A') imagen='alertaNaranja2.png';
           else if (urgencia=='M') imagen='alertaRoja2.png';
           else imagen='alertaVerde2.png';
       }
   }
   if (imagen!=="") return require('@/assets/'+imagen);
   else return null;
}
export function  getImagen_aviso(estado_aviso) {
   let imagen="";
   if (estado_aviso=="EP") imagen='email.jpg'
   else if (estado_aviso=="EE") imagen='emailok.jpg'
   else if (estado_aviso=="SP") imagen='sms.gif'
   else if (estado_aviso=="SE") imagen='smsok.gif'
   else if (estado_aviso=="WP") imagen='whatsapp.png'
   else if (estado_aviso=="WE") imagen='whatsappok.png'
   // console.log("getImagen_aviso",estado_aviso,imagen)
   if (imagen!=="") return require('@/assets/'+imagen);
   else return null;
}

//Devuelve el color de la marca que hay que pintar a la hora indicada
export function existe_marca(marcas,hora_inicio,hora_fin) { 
   let color='';
   if (marcas){
      for (let i=0; i<marcas.length; i++) {
          if (hora_inicio<marcas[i].fecha_fin && hora_fin>marcas[i].fecha) color=marcas[i].color;
      }
   }
   return color;
}
//Devuelve el color de la marca que hay que pintar a la hora indicada. Busca en la matriz de marcas total (la que tiene una matriz de dias y dentro de cada dia una matriz de marcas)
export function existe_marca2(marcas,hora_inicio,hora_fin) { 
   let color='';
   if (marcas){
      for (let j=0; j<marcas.length; j++) {
         for (let i=0; i<marcas[j].length; i++) {
            if (hora_inicio<marcas[j][i].fecha_fin && hora_fin>marcas[j][i].fecha) color=marcas[j][i].color;
        }
  
      }
   }
   return color;
}
export function elimina_marca(marcas,hora_inicio,hora_fin) { 
   let color='';
   if (marcas){
      for (let j=0; j<marcas.length; j++) {
         for (let i=0; i<marcas[j].length; i++) {
            if (hora_inicio<marcas[j][i].fecha_fin && hora_fin>marcas[j][i].fecha) marcas[j].splice(i,1)//color=marcas[j][i].color;
        }
  
      }
   }
   return color;
}




//=========================================================AGENDA=========================================================
export class Agenda {
   constructor (agenda) {
      this.cod = agenda.cod;
      this.nombre = agenda.nombre;
      this.tipo = agenda.tipo;
      this.colorCitas = agenda.colorCitas;
   }
   static in_array(matriz,agenda) {
      for (let i=0; i<matriz.length; i++) {
         if (matriz[i].cod === agenda.cod) return true;
      }
      return false;
   }
}


//=========================================================PERSONA=========================================================
export class Persona {
   constructor (persona) {
      this.cod = persona.cod;
      this.nombre = persona.nombre;
      this.apellidos = persona.apellidos;
      this.telefono = persona.telefono;
      this.email = persona.email;
      this.seudonimo = persona.seudonimo;
      this.autorizacionAviso = persona.autorizacionAviso;
      this.idiomaPreferido = persona.idiomaPreferido;
      this.aviso = persona.aviso;
   }
   getTelefonoAviso(){
      if (this.telefono.length< 9) return "";
  
      let numTelefonos=1;
      if (this.autorizacionAviso.length===2 && this.autorizacionAviso.substring(1,2)==="2") numTelefonos=2;
          
      let inicio = 0;
      let antNumero = false;
      let numEncontrados = 0;
      let resultado="";
      while (inicio < this.telefono.length - 8 && numEncontrados<numTelefonos) {	  
        let encontrado = false;
        if (!antNumero && (this.telefono.substring(inicio, inicio+1)==="6" || this.telefono.substring(inicio,inicio+1)==="7")) { //Si empieza un numero por 6 compruebo si existe 9 digitos seguidos(ignorando separadores)
          let movil = this.telefono.substring(inicio, inicio+1); 
          let longNumero = 1;
          let i = 1;
          let continua = true;
          while (inicio+i<this.telefono.length && continua && longNumero<9) {	  
            let ca=this.telefono.substring(inicio+i, inicio+i+1);
            if (ca>='0' && ca<='9') {
              movil+=ca;
              longNumero+=1;
            }
            else if (ca!=" " && ca!="." && ca!="," && ca!="-" && ca!="_" && ca!="/" && ca!="\\") continua = false;
            i+=1;
          }
          //Si he encontrado un numero compruebo que no tenga longitud mayor que 9
          if (longNumero==9) {
            encontrado = true;
            if (inicio + i <= this.telefono.length) {
              if (this.telefono.substring(inicio+i,inicio+i+1)>='0' && this.telefono.substring(inicio+i,inicio+i+1)<='9') {
                longNumero=1;
                encontrado=false;
              }
            }
            //Si el teléfono está repetido --> no lo añado
            if (encontrado){
              if (resultado.indexOf(movil)>=0) {
                  longNumero=1;
                  encontrado=false;
              }
            }
            if (encontrado) {
              if (resultado!="") resultado+=",";
              resultado+=movil;
              numEncontrados+=1;
            }
          }
        }
        
        if (  this.telefono.substring(inicio,inicio+1)>='0' && this.telefono.substring(inicio,inicio+1)<='9') antNumero=true;
        else antNumero=false;
        inicio+=1;
      }
      return resultado;      
   }
   getEmailAviso() {
      if (!esEmail(this.email)) return "";
      let numEmails=1;
      if (this.autorizacionAviso.length===2 && this.autorizacionAviso.substring(1, 2)==="2") numEmails=2;
      let emails=this.email.split(",");
      if (numEmails===1 || emails.length===1) return emails[0];
      if (numEmails===2 && emails.length>=2)  return emails[0]+","+emails[1];	    
   }
   getAutorizacionDescripcion() {
      let cad;
      if (this.autorizacionAviso!=="N" && this.autorizacionAviso!=="") {
         cad = i18n.t("personaautorizarecibirrecordatorios");
         if (this.autorizacionAviso.substring(0,1)==="S") cad +="<br/>"+i18n.t("enviarsiempresmspersona");
         else if (this.autorizacionAviso.substring(0,1)==="E") cad += "<br/>"+i18n.t("enviarsiempreemailpersona");                
         else if (this.autorizacionAviso.substring(0,1)==="W") cad += "<br/>"+i18n.t("enviarsiemprewhatsapppersona");                
      }
      else cad = i18n.t("personanoautorizarecibirrecordatorios");
      return cad;

   }
}

//=========================================================AVISO=========================================================
 
 export class Aviso {
   constructor (aviso) {
      this.tipo = aviso.tipo 
      this.horasEmail = aviso.horasEmail ?? "0";
      this.horasSms = aviso.horasSms ?? "0";
      this.horasWhatsapp = aviso.horasWhatsapp ?? "0";
      this.email = aviso.email ?? "";
      this.telefono = aviso.telefono ?? "";
      this.telefonoW = aviso.telefonoW ?? "";
      this.mensaje = aviso.mensaje ?? "";
      this.estado = aviso.estado ?? "";
   }
   getInformacion() {
      // console.log("tipo aviso",this.tipo)
      if (this.tipo==="N") return i18n.t("avisosnoestablecidos")
      let resul = "";
      let plural = false;
      if (this.tipo==="E" && this.email.indexOf(",")>0) plural = true;
      if (this.tipo==="S" && this.telefono.indexOf(",")>0) plural = true;
      if (this.tipo==="W" && this.telefonoW.indexOf(",")>0) plural = true;
      if (this.tipo==="S") {
         if (plural) resul = "2 "+i18n.t("SMSs");
         else resul = "1 "+i18n.t("SMS");
      }
      if (this.tipo==="E") {
         if (plural) resul = "2 "+i18n.t("Emails");
         else resul = "1 "+i18n.t("email");
      }
      if (this.tipo==="W") {
         if (plural) resul = "2 "+i18n.t("whatsapps");
         else resul = "1 "+i18n.t("whatsapp");
      }
      if (resul==="") return "";
      if (this.estado==="E") {
         if (plural) resul += " "+i18n.t("enviados");
         else resul += " "+i18n.t("enviado");
      } 
      else if (this.estado==="P") {
         if (plural) resul += " "+i18n.t("pendientesdeenvio");
         else resul += " "+i18n.t("pendientedeenvio");
      } 
      else if (this.estado==="A") {
         if (plural) resul += " "+i18n.t("anulados");
         else resul += " "+i18n.t("anulado");
      } 
      return resul;
   }
   getColor(){
      if (this.tipo==="N") return "black";
      if (this.estado==="E") return "blue";
      else if (this.estado==="P") return "green";
      else if (this.estado==="A") return "red";
   }
   mensajeIgual(aviso2) {
      if (!aviso2) return false;
      return (this.limpiaMensaje()===aviso2.limpiaMensaje()) 
   }
   limpiaMensaje() {
      return this.mensaje.replaceAll("#1","").replaceAll("#2","").replaceAll("#3","").replaceAll("#4","");
   }
   // mensajeCorrecto() {
   //    if (num_ocurrencias(this.mensaje,"#1") % 2 !==0) return false;
   //    if (num_ocurrencias(this.mensaje,"#2") % 2 !==0) return false;
   //    if (num_ocurrencias(this.mensaje,"#3") % 2 !==0) return false;
   //    if (num_ocurrencias(this.mensaje,"#4") % 2 !==0) return false;
   //    if (num_ocurrencias(this.mensaje,"#1")===0 && num_ocurrencias(this.mensaje,"#2")===0) return false;
   //    if (num_ocurrencias(this.mensaje,"#3")===0) return false;
   //    if (this.tipo==="S" && this.limpiaMensaje().length>160) return false;
   //    return true;
   // }
   esMensajeCorrecto() {
      return (this.getMensajeError()==="")
   }
   getMensajeError() {
      if (num_ocurrencias(this.mensaje,"#1") % 2 !==0) return i18n.t("formatomensajeincorrecto")+": #1";
      if (num_ocurrencias(this.mensaje,"#2") % 2 !==0) return i18n.t("formatomensajeincorrecto")+": #2";
      if (num_ocurrencias(this.mensaje,"#3") % 2 !==0) return i18n.t("formatomensajeincorrecto")+": #3";
      if (num_ocurrencias(this.mensaje,"#4") % 2 !==0) return i18n.t("formatomensajeincorrecto")+": #4";
      if (num_ocurrencias(this.mensaje,"#1")===0 && num_ocurrencias(this.mensaje,"#2")===0) return i18n.t("debeindicarfecha");
      if (num_ocurrencias(this.mensaje,"#3")===0) return i18n.t("debeindicarhora");
      if (this.tipo==="S" && this.limpiaMensaje().length>160) return i18n.t("longitudsmsexcede160");
      return "";
   }
}


//=========================================================CITA=========================================================
 

 export class Cita {
    //constructor (cod, agenda,tipo_cita,persona,acto,fecha,todoeldia,hora,duracion,urgencia,estado,descripcion,color) {
    constructor (cita) {
       this.cod = cita.cod;
       this.agenda = cita.agenda;
       this.agendas = cita.agendas;
       this.tipo_cita = cita.tipo_cita;
       if (cita.persona) this.persona = new Persona(cita.persona);
       else cita.persona = null;
       this.acto = cita.acto;
       this.fecha = cita.fecha;
       this.todoeldia = cita.todoeldia;
       this.hora = cita.hora;
       this.duracion = cita.duracion;
       this.urgencia = cita.urgencia;
       this.estado = cita.estado;
       this.descripcion = cita.descripcion;
       this.color = cita.color;
       this.recurso = cita.recurso;
       this.motivoAnulacion = cita.motivoAnulacion
       //console.log("cita_recurso",this.recurso,cita.recurso);
       this.recursos = cita.recursos;
       if (cita.aviso) this.aviso = new Aviso(cita.aviso); //Se carga con información de bbdd del último aviso y se actualiza segun lo que seleccione el usuario y segun los cambios en la  cita
       else this.aviso = new Aviso({tipo:'N'});
       if (cita.avisoEnviado) this.avisoEnviado = new Aviso(cita.avisoEnviado); //se carga la información del último aviso enviado de bbdd
       else this.avisoEnviado = null;
    }

    static colision(cita,cita2,esRecurso) { //Comprueba si existe colision con otra cita siendo la dos citas de la misma agenda
      // console.log("colision",cita,cita2)
      if (((!esRecurso && cita.agenda_nombre == cita2.agenda_nombre) || (esRecurso && cita.recurso_nombre == cita2.recurso_nombre)) &&
          (cita.top+2<cita2.top+cita2.height && cita.top+cita.height-2>cita2.top)) return true;

      return false;

    }

    creaAvisoVacio(){
       if (!this.persona) return new Aviso({tipo:'N'});
       return new Aviso({tipo:'N',horasEmail:getParametro("horasEmail"),horasSms:getParametro("horasEmail"),horasWhatsapp:getParametro("horasWhatsapp"),
                        email:this.persona.getEmailAviso(),telefono:this.persona.getTelefonoAviso(),telefonoW:this.persona.getTelefonoAviso(),
                        mensaje:this.getMensajeAviso(),estado:''})
    }
    

    calculaAvisoAutomatico(cambiandoPersona) { //calculo el aviso si está en modo automatico o si tiene aviso asignado aviso cuando se cambia el paciente, fecha, hora,agenda
      if (!this.persona || !this.fecha || !this.hora || (!this.agenda && (!this.agendas || this.agendas.length===0))) {
         this.aviso = this.creaAvisoVacio();
         return;
      }

      //Si la cita la estamos pasando a Espera,aTendida o No acude-> no se genera aviso
      if (this.estado==="E" || this.estado==="T" || this.estado==="N") {
         //this.aviso = null;
         if (this.avisoEnviado)
            this.aviso = this.avisoEnviado;
         else
            this.aviso = this.creaAvisoVacio();
         return;
      }

      // console.log("calculaAvisoAutomatico",this.aviso,this.persona.autorizacionAviso)

      let avisoAutomatico="";
      if (this.aviso && !cambiandoPersona) avisoAutomatico = this.aviso.tipo.substring(0,1);
      else {
         if (this.persona.autorizacionAviso==="") this.persona.autorizacionAviso = "N";
         if (this.persona.autorizacionAviso.substring(0,1)==="S") avisoAutomatico="S";
         else if (this.persona.autorizacionAviso.substring(0,1)==="E") avisoAutomatico="E";  
         else if (this.persona.autorizacionAviso.substring(0,1)==="W") avisoAutomatico="W";  
      }

      //calculo el aviso si la persona tiene "avisar siempre" o si tiene aviso asignado(siempre que no esté cambiando la persona)
      //if (avisoAutomatico==="" && (!this.aviso || cambiandoPersona)) {
      if ((avisoAutomatico==="" || avisoAutomatico==="N") ) {
         this.aviso = this.creaAvisoVacio();
         return;
      }

      // console.log("enviarRecordatorio",this.acto.enviarRecordatorio)
      //Si el acto tiene enviarRecordario=N -> no genero aviso
      if (this.acto && this.acto.enviarRecordatorio==="N") {
         this.aviso = this.creaAvisoVacio();
         return;
      }

      //Calculamos el nuevo aviso
      let tipo="",email="",telefono="",telefonoW="",mensaje="";
      
      // console.log("llego1",avisoAutomatico)
      if (this.estado==="P" || this.estado==="D") {
         //Si el estado del aviso actual del aviso no es enviado o estando enviado la opcion avisarModificacionCita esta activa
         if (this.avisoEnviado && getParametro("avisarModificacionCita")!=="1") {
            if (this.avisoEnviado)
              this.aviso = this.avisoEnviado;
            else
              this.aviso = this.creaAvisoVacio();
            return;   
         }

         //Miro si hay que avisarle por sms o email
         if (!cambiandoPersona && this.aviso && this.aviso.tipo!=="N") {
            //tipo = this.aviso.tipo;
            telefono = this.aviso.telefono;
            email = this.aviso.email;
            telefonoW = this.aviso.telefonoW;
         }

         if (avisoAutomatico==="S")  tipo = "S";
         else if (avisoAutomatico==="E") tipo = "E";
         else if (avisoAutomatico==="W") tipo = "W";

         if (email==="") email=this.persona.getEmailAviso();
         if (telefono==="") telefono=this.persona.getTelefonoAviso();
         if (telefonoW==="") telefonoW=this.persona.getTelefonoAviso();

         if ((tipo==="E" && email==="") || (tipo==="S" && telefono==="") || (tipo==="W" && telefonoW==="")) {
            if (this.avisoEnviado)
              this.aviso = this.avisoEnviado;
            else
              this.aviso = this.creaAvisoVacio();
            return;   
         }

         //Genero nuevo aviso
         mensaje = this.getMensajeAviso();
         const nuevoAviso = new Aviso({tipo:tipo,horasEmail:getParametro("horasEmail"),horasSms:getParametro("horasSMS"),horasWhatsapp:getParametro("horasWhatsapp"),
                                 email:email,telefono:telefono,telefonoW:telefonoW,mensaje:mensaje,estado:"P"})
         if (nuevoAviso.mensajeIgual(this.avisoEnviado)) {
            this.aviso = this.avisoEnviado;
         }
         else this.aviso = nuevoAviso;
      }
      else if (this.estado ==="A") {
         //Si no esta activa la opcion avisarAnulacionCita --> no se genera mensaje
         if (getParametro("avisarAnulacionCita")!=="1") {
            //this.aviso = null;
            if (this.avisoEnviado)
              this.aviso = this.avisoEnviado;
            else
              this.aviso = this.creaAvisoVacio();
            return;   
         }

         //Miro si hay que avisarle por sms o email
         if (this.aviso && this.aviso.tipo!=="N") {
            tipo = this.aviso.tipo;
            telefono = this.aviso.telefono;
            email = this.aviso.email;
            telefonoW = this.aviso.telefonoW;
         }
         else if (avisoAutomatico==="S" || (this.aviso && this.aviso.tipo==="S")) {
            tipo = "S";
         }
         else if (avisoAutomatico==="E" || (this.aviso && this.aviso.tipo==="E")) {
            tipo = "E";
         }
         else if (avisoAutomatico==="W" || (this.aviso && this.aviso.tipo==="W")) {
            tipo = "W";
         }
         if (email==="") email=this.persona.getEmailAviso();
         if (telefono==="") telefono=this.persona.getTelefonoAviso();
         if (telefonoW==="") telefonoW=this.persona.getTelefonoAviso();

         if ((tipo==="E" && email==="") || (tipo==="S" && telefono==="") || (tipo==="W" && telefonoW==="")) {
            //this.aviso = null;
            if (this.avisoEnviado)
              this.aviso = this.avisoEnviado;
            else
              this.aviso = this.creaAvisoVacio();
            return;   
         }
         //Genero nuevo aviso
         mensaje = this.getMensajeAviso();
         const nuevoAviso = new Aviso({tipo:tipo,horasEmail:getParametro("horasEmail"),horasSms:getParametro("horasSMS"),horasWhatsapp:getParametro("horasWhatsapp"),
                                 email:email,telefono:telefono,telefonoW:telefonoW,mensaje:mensaje,estado:"P"})
         if (nuevoAviso.mensajeIgual(this.avisoEnviado)) this.aviso = this.avisoEnviado;
         else this.aviso = nuevoAviso;


      }
    }

    calculaAvisoSeleccionUsuario() { //calculo el aviso cuando el usuario cambia a aviso por sms o email 
      
      //ESTA SITUACION NO SE DEBERIA DE DAR PORQUE NO DEBERIA HABER PODIDO ENTRAR EN LA PANTALLA
      // if (!this.persona || !this.fecha || !this.hora || !this.agenda) {
      //    console.log("No avisossssssssss");
      //    //this.aviso = null;
      //    this.aviso = new Aviso({tipo:'N'});
      //    return;
      // }

      //Si la cita la estamos pasando a Espera,aTendida o No acude-> no se genera aviso
      //ESTA SITUACION NO SE DEBERIA DE DAR PORQUE NO DEBERIA HABER PODIDO ENTRAR EN LA PANTALLA
      // if (this.estado==="E" || this.estado==="T" || this.estado==="N") {
      //    this.aviso = this.creaAvisoVacio();
      //    return;
      // }


      //calculo el aviso si está en modo automatico o si tiene aviso asignado(siempre que no esté cambiando la persona)
      if (this.aviso.tipo==="N") {
         if (this.avisoEnviado)
            this.aviso = this.avisoEnviado;
         else
           this.aviso = this.creaAvisoVacio();
         // console.log("aviso:")
         // console.log(this.aviso)
         return;
      }

      //Calculamos el nuevo aviso
      let tipo="";
      
      if (this.aviso.tipo==="S") {
         tipo = "S";
      }
      else if (this.aviso.tipo==="E") {
         tipo = "E";
      }
      else if (this.aviso.tipo==="W") {
         tipo = "W";
      }
      // console.log("calculaAvisoSeleccionUsuario",this.aviso.tipo,tipo);
      this.aviso = new Aviso({tipo:tipo,horasEmail:this.aviso.horasEmail,horasSms:this.aviso.horasSms,horasWhatsapp:this.aviso.horasWhatsapp,email:this.aviso.email,
                        telefono:this.aviso.telefono,telefonoW:this.aviso.telefonoW,mensaje:this.aviso.mensaje,estado:"P"})
      // console.log("calculaAvisoSeleccionUsuario:",this.aviso)

    }

    getMensajeAviso() {
       let tipoAviso;
       if (this.estado==="A") tipoAviso = "textoAnulacion";
       else tipoAviso = "textoAviso";
       let idioma = this.persona.idiomaPreferido; 
       if (!idioma) idioma = getParametro("idiomaPreferidoDefecto");
       if (!idioma) idioma = "1";
       let mensaje = getParametro(tipoAviso + idioma);
       mensaje = mensaje.replace("#1DIASEMANA#1","#1"+nombre_dia_semana(this.fecha,idioma)+"#1");
       if (idioma==IDIOMA_EUSKERA) mensaje = mensaje.replace("#2DIAMESYAÑO#2","#2"+date_to_char(this.fecha,"yyyy/mm/dd")+"#2");
       else mensaje = mensaje.replace("#2DIAMESYAÑO#2","#2"+date_to_char(this.fecha,"dd/mm/yyyy")+"#2");
       mensaje = mensaje.replace("#3HO:MI#3","#3"+this.hora+"#3");
       let nagenda='';
       if (getParametro("citaVariasAgendas")==="S") {
         for (let i=0;i<this.agendas.length; i++) {
            if (nagenda!=="") nagenda += ",";
            nagenda += this.agendas[i].nombre;
         }
       }
       else nagenda = this.agenda.nombre;
       mensaje = mensaje.replace("#4AGENDA#4","#4"+nagenda+"#4");
       return mensaje;
    }

    getInformacionAvisos() {
       //Si hay aviso muestro la información del aviso, en caso contratrio muestro la información del aviso enviado
       if (this.aviso) {
          //Si hay aviso enviado y es similar al aviso -> lo muestro como enviado
          if (this.aviso.mensajeIgual(this.avisoEnviado)) return this.avisoEnviado.getInformacion();
          //Si el aviso esta a N y hay avisoEnviado -> muestro el mensaje enviado
          if (this.aviso.tipo=="N" && this.avisoEnviado) return this.avisoEnviado.getInformacion();
          return this.aviso.getInformacion();
       }
       else if (this.avisoEnviado) return this.avisoEnviado.getInformacion();
       else return i18n.t("avisosnoestablecidos")
    }
    getColorAvisos() {
      //Si hay aviso muestro la información del aviso, en caso contratrio muestro la información del aviso enviado
      if (this.aviso) {
         //Si hay aviso enviado y es similar al aviso -> lo muestro como enviado
         if (this.aviso.mensajeIgual(this.avisoEnviado)) return this.avisoEnviado.getColor();
          //Si el aviso esta a N y hay avisoEnviado -> muestro el mensaje enviado
          if (this.aviso.tipo=="N" && this.avisoEnviado) return this.avisoEnviado.getColor();
         return this.aviso.getColor();
      }
      else if (this.avisoEnviado) return this.avisoEnviado.getColor();
      else return "black";
   }
  
 } //Fin class Cita


