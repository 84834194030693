import en from './assets/i18n/en.json'
import es from './assets/i18n/es.json'
import pt from './assets/i18n/pt.json'
import ca from './assets/i18n/ca.json'
import de from './assets/i18n/de.json'
import eu from './assets/i18n/eu.json'
import fr from './assets/i18n/fr.json'
import gl from './assets/i18n/gl.json'
import it from './assets/i18n/it.json'

import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

export default new VueI18n({
    locale: localStorage.getItem('lang') || 'es',
    messages: {
        en: en,
        es: es,
        pt: pt,
        ca: ca,
        de: de,
        eu: eu,
        fr: fr,
        gl: gl,
        it: it
    }
});